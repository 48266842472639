/* eslint-disable react/prop-types */
import React from 'react'
import { useTheme } from 'emotion-theming'
import {
  Banner as HorizonBanner,
  Container,
  Header as HorizonHeader,
  GlobalNav,
  Announcement,
} from 'ascential-horizon'
import getThemeConfig from '../../providers/config/themeConfig'
import { trackBtnClick, trackLinkClick } from '../../services/tracking'
import { useHasMounted } from '../../utils/hooks'
import { useIdentity } from '../../providers/identityContext'
import { HeaderBanner, HeaderWrapper } from './index.style'

const Stickyfill = process.browser ? require('stickyfilljs') : null
const path = require('path')

// TODO - fix active link navigation
function Header({ pageType, location, announcement, ...header }) {
  const hasMounted = useHasMounted()
  const { login, loggedIn, logout, user } = useIdentity()

  if (hasMounted && typeof Stickyfill !== 'undefined') {
    const stickyHeader = document.querySelectorAll('.sticky')
    Stickyfill.add(stickyHeader)
  }
  const { banner: bannerTheme } = useTheme()
  const {
    header: { position, place, variation, loginRequired },
  } = getThemeConfig()
  const isSticky = position === 'sticky'
  const isMega = variation === 'mega'
  const isMember =
    user &&
    user['http://lions.membership/user/plan'] &&
    user['http://lions.membership/user/plan'].isMember
  const pageSlug = pageType && pageType.slug

  const dashboardLink = {
    label: 'Member Dashboard',
    url: '/membersonly',
  }

  const slugWithoutSlash =
    pageSlug && pageSlug[0] === '/' ? pageSlug.slice(1) : pageSlug

  const navigation = header && header.primaryNavigation
  const utilityNavigation =
    loginRequired && isMember
      ? [
          {
            label: 'Member Dashboard',
            url: 'https://www.lionscreativity.com/membersonly',
          },
        ]
      : (header && header.utilityNavigation) || []
  const trackedUtilityNav = utilityNavigation.map((util) => ({
    ...util,
    onClick: () =>
      trackLinkClick(
        util && util.label,
        util && util.url,
        'topNav',
        'link.click'
      ),
  }))
  const banner = (header && header.banner) || false

  const actionNavigation = (header && header.action) || []
  const trackedActionNav = actionNavigation.map((action) => ({
    ...action,
    onClick: () =>
      trackLinkClick(
        action && action.label,
        action && action.url,
        'topNav',
        'link.click'
      ),
  }))

  let accountProps

  const userBtn = loggedIn
    ? {
        label: 'Sign out',
        url: '/',
        onClick: () => logout(),
      }
    : {
        label: 'Sign in',
        url: '/',
        onClick: () => login(),
      }

  if (loggedIn) {
    const avatarLinks = []
    const profile = user && user['http://lions.membership/user/profile']

    const hasName = profile && profile.FirstName && profile.LastName
    const initials = hasName
      ? profile.FirstName.charAt(0) + profile.LastName.charAt(0)
      : ''

    if (isMember) {
      avatarLinks.push(dashboardLink)
    }

    avatarLinks.push(
      {
        label: 'My Account',
        url: '/my-account',
      },
      {
        label: 'My Orders',
        url: '/plan-manager',
      },
      {
        label: 'Sign Out',
        url: '/logout',
      }
    )
    accountProps = {
      avatar: {
        label: initials,
        description: '',
      },
      avatarPrimaryLink: '/my-account',
      avatarSecondaryLink: isMember
        ? dashboardLink
        : {
            label: 'Join LIONS Membership',
            url: '/membership',
            onClick: () =>
              trackLinkClick(
                'Join LIONS Membership',
                '/membership',
                'topNav',
                'link.click'
              ),
          },
      avatarMenuLinks: avatarLinks,
    }
  } else {
    accountProps = {
      accountPrimaryLinks: [
        {
          label: 'Sign in',
          url: '/',
          onClick: login,
        },
        {
          label: 'Join LIONS Membership',
          url: '/membership',
          onClick: () =>
            trackLinkClick(
              'Join LIONS Membership',
              '/membership',
              'topNav',
              'link.click'
            ),
        },
      ],
    }
  }

  const avatarMenu = accountProps && accountProps.avatarMenuLinks

  const accountLinksSlider = avatarMenu && [
    {
      item: { label: 'Lions Account', url: null },
      description: 'Access your Lions account',
      subNavigation: {
        navItems: avatarMenu,
      },
    },
  ]

  const navLinks =
    (isMega &&
      avatarMenu &&
      navigation &&
      navigation.concat(accountLinksSlider)) ||
    navigation

  const primaryLinks =
    (navLinks &&
      navLinks.map((item) => {
        const navItems =
          (item.subNavigation && item.subNavigation.navItems) || []
        const trackedNavItems = navItems.map((subnav) => ({
          ...subnav,
          onClick: () =>
            trackLinkClick(
              subnav && subnav.label,
              subnav && subnav.url,
              'topNav',
              'link.click'
            ),
        }))

        return {
          label: item && item.item.label,
          url: item && item.item.url,
          description: item && isMega && item.description,
          onClick: () =>
            trackLinkClick(
              item && item.item.label,
              item && item.item.url,
              'topNav',
              'link.click'
            ),
          navItems: trackedNavItems,
        }
      })) ||
    []
  const activePage =
    (navigation &&
      navigation.filter(
        (item) =>
          item.item &&
          item.item.url &&
          pageSlug &&
          pageSlug === item.item.url.replace('/', '')
      )) ||
    false

  const secondaryLinks =
    (activePage &&
      activePage[0] &&
      activePage[0].subNavigation &&
      activePage[0].subNavigation.navItems) ||
    []
  const trackedSecLinks = secondaryLinks.map((subnav) => ({
    ...subnav,
    onClick: () =>
      trackLinkClick(
        subnav && subnav.label,
        subnav && subnav.url,
        'topNav',
        'link.click'
      ),
  }))

  const menuFooterPrimaryNav = (header && header.menuFooterPrimaryNav) || []
  const trackedMenuFooterPrimaryNav = menuFooterPrimaryNav.map((link) => ({
    ...link,
    onClick: () =>
      trackLinkClick(
        link && link.label,
        link && link.url,
        'topNav',
        'link.click'
      ),
  }))

  const menuFooterSecondaryNav = (header && header.menuFooterSecondaryNav) || []
  const trackedMenuFooterSecondaryNav = menuFooterSecondaryNav.map((link) => ({
    ...link,
    onClick: () =>
      trackLinkClick(
        link && link.label,
        link && link.url,
        'topNav',
        'link.click'
      ),
  }))

  const logoImage = header.logoImage ? { ...header.logoImage } : null

  if (logoImage && !logoImage.fluid) {
    logoImage.src = logoImage.file.url
  }

  const headerProps = header && {
    actions: trackedActionNav,
    cta: header.ctaUrl &&
      header.ctaLabel && {
        url: header.ctaUrl,
        label: header.ctaLabel,
        onClick: () => trackBtnClick(header.ctaLabel, header.ctaUrl),
      },
    homeLink: '/',
    logoImage,
    place,
    primaryActive: `/${slugWithoutSlash}`,
    primaryLinks,
    secondaryActive: path.join('/', pageSlug || '', slugWithoutSlash || ''),
    secondaryLinks: trackedSecLinks,
    sliderFooterPrimaryNav: trackedMenuFooterPrimaryNav,
    sliderFooterSecondaryNav: trackedMenuFooterSecondaryNav,
    userBtn: loginRequired ? userBtn : null,
    utilityLinks: trackedUtilityNav,
    variant: variation,
  }

  if (loginRequired) {
    headerProps.account = accountProps
  }

  if (banner && banner.image && !banner.image.fluid) {
    banner.image.src = banner.image.file.url
  }

  const bannerProps = banner && {
    ...banner,
    position: 'flex-end',
    textSize: 'large',
    size: 'large',
    textColor: bannerTheme.headerTextColor,
    useProgressiveImage: false,
  }

  const announcementSubtitle =
    announcement &&
    announcement.description &&
    announcement.description.childMarkdownRemark &&
    announcement.description.childMarkdownRemark.html

  const announcementCta =
    (announcement &&
      announcement.ctaLabel &&
      announcement.ctaUrl && {
        label: announcement.ctaLabel,
        url: announcement.ctaUrl,
      }) ||
    null

  const announcementProps = announcement && {
    title: announcement.title || '',
    subtitle: announcementSubtitle || '',
    button: announcementCta,
    variant: announcement.variant,
  }

  const headerContent = (
    <>
      {announcementProps && <Announcement {...announcementProps} />}
      {!isMega && (
        <HorizonHeader
          {...headerProps}
          position={position}
          variation={variation}
          place={place}
        />
      )}
      {isMega && <GlobalNav {...headerProps} position={position} />}
      {banner && (
        <HeaderBanner isSticky={isSticky} secondaryLinks={secondaryLinks}>
          <Container>
            <HorizonBanner {...bannerProps} />
          </Container>
        </HeaderBanner>
      )}
    </>
  )

  return headerProps ? (
    <>
      {isSticky ? (
        <HeaderWrapper className="sticky" isSticky={isSticky}>
          {headerContent}
        </HeaderWrapper>
      ) : (
        <HeaderWrapper>{headerContent}</HeaderWrapper>
      )}
    </>
  ) : null
}

export default Header
